import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/index';
import { urlConstants } from '../../../constants/urlConstants';

export const uploadCustomer = createApi({
    reducerPath: 'uploadCustomer',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`);
            return headers;
        },
    }),

    endpoints: (builder) => ({
        usersBulkImport: builder.mutation<any, any>({
            query: (fileData: any) => ({
                url: urlConstants?.BULK_IMPORT,
                method: 'POST',
                body: fileData
            }),
        }),
        fetchSampleFile: builder.query<Blob, void>({
            query: () => ({
                url: urlConstants?.SAMPLE_FILE,
                method: 'GET',
                responseHandler: (response) => response.blob()
            }),
        }),
    }),
});

export const { useUsersBulkImportMutation, useFetchSampleFileQuery } = uploadCustomer;
