import CustomerReviewFormAdd from "../pages/customerReviewForm/customerReviewFormAdd/customerReviewFormAdd";

export const PATH = {
  REGISTER: '/register',
  LOGIN: '/',
  
  DASHBOARD: '/dashboard',
  GRE_LIST: '/gre-list',
  PROJECTS: '/projects',

  LEADS: '/leads',
  NOT_FOUND:'/page-not-found',
  ACQUISITION_FORM_MAIN: '/acquisition-form-main',
  ACQUISITION_FORM_MAIN_SUCCESS: '/acquisition-form-main-success',
 
  ACQUISITION_FORM: '/acquisition/add-acquisition',
  ACQUISITION_LIST: '/acquisition/acquisition-list',
  ACQUISITION_LIST_TYPE: '/acquisition/acquisition-list/:type?/:id?',

  ACQUISITION_TYPE: '/acquisition/:type?/:id?',

  ROLE_PERMISSION: '/role-permission',

  USERS_LIST: '/user/users-list',
  ADD_USER: '/user/add-user',
  USER_TYPE: '/user/:type?/:id?',

  DEPARTMENT_ADD: '/department/add-department',
  DEPARTMENT_LIST: '/department/department-list',
  DEPARTMENT_TYPE: '/department/:type?/:id?',

  STATUS_LIST: '/status/status-list',
  STATUS_ADD: '/status/add-status',
  STATUS_TYPE: '/status/:type?/:id?',

  OCCUPATION_LIST: '/occupation/occupation-list',
  OCCUPATION_ADD: '/occupation/add-occupation',
  OCCUPATION_TYPE: '/occupation/:type?/:id?',

  AGE_LIST: '/age/age-list',
  AGE_TYPE: '/age/:type?/:id?',
  AGE_ADD: '/age/add-age',

  KNOW_ABOUT_US_LIST: '/knowAboutUs/knowAboutUs-list',
  KNOW_ABOUT_US_ADD: '/knowAboutUs/add-knowAboutUs',
  KNOW_ABOUT_US_TYPE: '/knowAboutUs/:type?/:id?',
  
  ROLES_LIST: '/role/roles-list',
  ADD_ROLE: '/role/add-role',
  ROLE_TYPE: '/role/:type?/:id?',

  PREFERRED_UNITS_LIST: '/preferred-units/preferred-units-list',
  PREFERRED_UNITS_ADD: '/preferred-units/add-unit',
  PREFERRED_UNITS_TYPE: '/preferred-units/:type?/:id?',

  PREFERRED_BUDGET_RANGE_ADD: '/budget/add-budget',
  PREFERRED_BUDGET_RANGE_LIST: '/budget/preferred-budget-list',
  PREFERRED_BUDGET_RANGE_TYPE: '/budget/:type?/:id?',

  PREFERRED_SIZE_ADD: '/preferred-size/add-size',
  PREFERRED_SIZE_LIST: '/preferred-size/preferred-size-list',
  PREFERRED_SIZE_TYPE: '/preferred-size/:type?/:id?',

  PURPOSE_OF_PURCHASE_ADD: '/purpose-of-purchase/add-purpose',
  PURPOSE_OF_PURCHASE_LIST: '/purpose-of-purchase/purpose-of-purchase-list',
  PURPOSE_OF_PURCHASE_TYPE: '/purpose-of-purchase/:type?/:id?',

  PROJECT_ADD: '/project/add-project',
  PROJECT_LIST: '/project/project-list',
  PROJECT_TYPE: '/project/:type?/:id?',

  FIELD_EMPLOYEES_ADD: '/field-employees/add-employee',
  FIELD_EMPLOYEES_LIST: '/field-employees/field-employees-list',
  FIELD_EMPLOYEES_TYPE: '/field-employees/:type?/:id?',

  REPORT_LIST: '/report-list',
  ACCESS_DENIED: '/access-denied',

  EXISTING_LEAD: '/existing-lead/:id?',

  ACQUISITION_FEEDBACK: '/acquisition-feedback',

  CUSTOMER_REVIEW_FORM_LIST: '/customer-review-form-list',
  CUSTOMER_REVIEW_FORM_ADD: '/customer-review-form-add',
  CUSTOMER_REVIEW_FORM_TYPE: '/customer-review-form/:type?/:id?',

  CUSTOMER_REVIEW_FORM_SUCCESS:'/acquisition-feedback-form-success',
  CUSTOMER_REVIEW_REPORT: '/customer-review-report',
  
  AREAOFRESIDENT_ADD: '/area-of-residence/add-area',
  AREAOFRESIDENT_LIST: '/area-of-residence/area-of-residence-list',
  AREAOFRESIDENT_TYPE: '/area-of-residence/:type?/:id?',

  //upload customer
  CUSTOMER_UPLOAD: '/upload-customer',

};

